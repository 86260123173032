.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}



.logo {
    width: 120px;
    height: auto;
}

.shop-icon {
    width: 24px;
    height: 24px;
}




