.question-form {
    width: 100%;
    /* height: 90vh; */
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.question {
    font-size: 16px;
    color: white;
    font-family: 'Noto Sans';
    line-height: 1.3;
    width: 80%;
    /* height: 100px; */
}

.question-number-title {
    font-size: 12px;
    color: white;
    font-family: 'Noto Sans';
}

.alert-popup {
    position: absolute;
    top: 70px;
    font-size: 16px;
    color: rgb(204, 30, 30);
    background-color: rgb(255, 207, 207);
    border: 1px solid rgb(244, 132, 132);
    padding: 5px 16px;
    border-radius: 12px;
}

@media (max-width: 768px) {
    .question {
        width: 100%;
        height: unset;
    }

    .question-form {
    gap: 20px;
    margin-top: 100px;
    }

    .answer-area { 
    padding: 16px;
    }

    .edit-buttons {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.answer-area {
    border-radius: 6px;
    width: 100%;
    font-size: 16px;
    padding: 50px 30px 80px 30px;
    font-family: 'Noto Sans';
    resize: none;
    border: none;
    transition: 300ms;
    line-height: 1.5;
    overflow-y: hidden;
    min-height: 300px;
}

.question-form-answer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.question-answer-label {
    font-size: 12px;
    color: black;
    font-family: 'Noto Sans';
    position: absolute;
    top: 30px;
    left: 30px;
}

.answer-area:focus {
    outline: 0px;
}

.edit-buttons {
    display: flex;
    gap: 5px;
    position: absolute;
    bottom: 20px;
    left: 30px;
}

.edit-button, .confirm-button {
    padding: 10px 14px;
    border: 0px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    font-family: 'Noto Sans';
    /* font-weight: 700; */
}

.preview-button {
    border: 0px;
    border-radius: 6px;
    cursor: pointer;
    padding: 5px;
}

.viewicon {
    width: 32px;
    height: auto;
}

.question-form-question {
    display: flex;
    flex-direction: column;
    padding: 26px;
    border-radius: 6px;
    background: linear-gradient(0deg, rgba(34,38,47,1) 0%, rgba(42,52,76,1) 100%);
    gap: 10px;
    width: 100%;
}


.confirm-button:not(:disabled),
.edit-button:not(:disabled) {
  background-color: #363E50;
  color: white;
}

.preview-button:not(:disabled) {
    background-color: #363E50;
    color: white;
}


.confirm-button:disabled,
.edit-button:disabled, .preview-button:disabled {
  background-color: grey;
  color: #ccc;
  cursor: not-allowed;
}

.answer-area:not(:disabled) {
    /* background-color: #EFEFEF; */
    background-color: white;
    border: 1px solid #D9D9D9;
}

.answer-area:disabled {
    background-color: #DDDDDD;
}

@media (max-width: 900px) {
    .answer-area {
        padding: 50px 30px 100px 30px;
    }
}