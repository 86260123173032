
.sidebar-mobile {
  position: fixed;
  left: 0;
  top: 0;
  width: 10%;
  height: 100vh;
  overflow-y: auto;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1000;
}

.sidebar-mobile::-webkit-scrollbar {
  display: none;
}

.hamburger-icon {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 30px;
  color: black;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10000;
  line-height: 1;
}

.sidebar-mobile.open {
  width: 80%;
  background: linear-gradient(360deg, rgba(18,22,31,1) 0%, rgba(39,46,61,1) 100%);
  align-items: center;
  overflow-y: auto;
}

/* .sidebar-mobile.open .hamburger-icon {
  color: white;
} */

.questions-list-mobile {
  list-style-type: none;
  width: 100%;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 300px;
}

.photo-list-mobile {
  list-style-type: none;
  width: 100%;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 300px;
}

.questions-list-mobile li button {
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 10px;
  font-family: "Noto Sans";
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.questions-list-mobile li button:hover {
  background-color: #ececec;
}

@media (min-width: 768px) {
  .sidebar-mobile {
    width: 20%;
  }
  .hamburger-icon {
    display: none;
  }
}

.forms-info-container-mobile {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px 20px 20px 20px;
  gap: 5px;
  /* background-color: black; */
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
}

.forms-mobile {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.forms-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.page-numbers {
  margin-top: 10px;
  color: white !important;
  font-size: 12px;
  /* font-weight: 700; */
  font-family: "Noto Sans";
}

progress {
  width: 90%;
  height: 12px;
  color: white;
  background-color: transparent;
  border: 0.5px solid white;
  border-radius: 32px;
  margin-top: 10px;
  -webkit-appearance: none;
  appearance: none;
}

progress::-webkit-progress-bar {
  background-color: transparent;
  border-radius: 32px;
}

progress[value]::-webkit-progress-value {
  background-color: #6d94c9;
  /* border-radius: 32px; */
  border-radius: 32px 0 0 32px;
}

progress[value="1"]::-webkit-progress-value {
  border-radius: 32px; /* Fully rounded when progress is at the minimum */
}

progress[value="100"]::-webkit-progress-value {
  border-radius: 32px; /* Fully rounded when progress is at the maximum */
}

progress::-moz-progress-bar {
  background-color: transparent;
  border-radius: 32px;
}

.forms-button {
  background-color: black;
  color: white;
  font-size: 16px;
  margin-top: 30px;
  padding: 10px 40px;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
}

.sidebar-bottom-fixed-mobile {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  gap: 5px;
  /* background-color: black; */
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
}

.photo-list-mobile {
  list-style-type: none;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  overflow-y:scroll;
}

.photo-list-one-mobile {
  text-align: left;
  width: 100%;
  background-color: #2b2b2b;
  border: none;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
  font-family: "Noto Sans";
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.photo-list-one-mobile-test {
  text-align: center;
  border: none;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
  font-family: "Noto Sans";
  font-size: 12px;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
}

.sidebar-bottom-add-photo-mobile {
  display: flex;
  width: 100%;
}

.sidebar-bottom-add-photo-mobile button {
  border: 0.5px solid rgb(194, 194, 194);
  background-color: transparent;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-family: "Noto Sans";
  padding: 12px 0px;
  color: white;
  margin-bottom: 150px;
  width: 100%;
}
