.login-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 120px;
}


.login-title {
    font-size: 36px;
    font-weight: 500;
    color: black;
}

form {
    width: 100%;
    gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    background-color: #EFEFEF;
    border-radius: 8px;
    border: 0px;
    height: 60px;
    width: 360px;
    font-size: 16px;
    padding-left: 20px;
}

input:focus {
    outline: 0px;
}

.login-button {
    height: 60px;
    width: 360px;
    background-color: black;
    color: white;
    border: 0px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
}

.login-error {
    font-size: 16px;
    text-align: center;
    color: crimson;
    font-family: Arial, Helvetica, sans-serif;
}

