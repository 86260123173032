.main-container {
    padding: 200px 120px 160px 120px;
    background-color: #EDEDED;
    position: absolute;
    top: 0;
    left: 0;
} 

.main-forms {
    display: flex;
    flex-direction: column;
}

.main-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 60px;
    position: relative;
}

.main-form-question {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
}

.main-form-answer {
    border-radius: 8px;
    width: 100%;
    font-size: 16px;
    padding: 20px 20px 30px 20px;
    font-family: 'Noto Sans';
    resize: none;
    border: none;
    line-height: 1.5;
    overflow-y: hidden;

    min-height: 180px;

    position: relative;
}

.main-form-answer:focus {
    outline: none;
}

.word-counter {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-align: right;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    font-size: 12px;
    font-family: 'Inter';
}
.word-counter.red {
    color: #A44646;
}
.word-counter.black {
    color: rgb(51, 51, 51);
}

.main-form-chapter {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 700;
}

.main-buttons {
    display: flex;
}

.main-edit {
    background-color: black;
    padding: 10px 20px;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-family: 'Inter';
    cursor: pointer;
}

.main-edit:disabled {
    background-color: rgb(98, 98, 98);
    color: rgb(204, 204, 204)
}

.main-done {
    background-color: rgb(73, 224, 134);
    padding: 10px 20px;
    border-radius: 8px;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-family: 'Inter';
    cursor: pointer;
}

.main-wait {
    background-color: rgb(224, 133, 73);
    padding: 10px 20px;
    border-radius: 8px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: 'Inter';
    cursor: pointer;
}

.main-popup-container {
    height: 100vh;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(40px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    padding: 0 10px
}

.main-popup {
    background-color: white;
    border-radius: 16px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    max-width: 500px;
    border: 1px solid rgb(183, 183, 183)
}

.main-popup-title {
    font-size: 18px;
    font-family: 'Inter';
    font-weight: 600;
    text-align: center;
}

.main-popup-text {
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 300;
    text-align: center;
    margin-bottom: 20px;
}

.main-popup-button {
    background-color: black;
    padding: 10px 20px;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-family: 'Inter';
    cursor: pointer;
}

.main-popup-close {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: black;
    font-family: 'Inter';
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 900px) {
    .main-container {
        padding: 120px 10px 160px 10px;
    } 

    .main-form-question {
        font-size: 14px;
    }
    
    .main-form-answer {
        font-size: 14px;
    }
}