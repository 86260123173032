.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 17%;
  overflow-y: auto;
  background: linear-gradient(360deg, rgba(18,22,31,1) 0%, rgba(39,46,61,1) 100%);
  /* padding: 20px; */
  height: 100vh;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.page-numbers {
  margin-top: 10px;
  color: white;
  font-size: 12px;
  /* font-weight: 700; */
  font-family: "Noto Sans";
}

.forms {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.forms-info-container {
  display: flex;
  flex-direction: column;
  width: 17%;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px 20px 20px 20px;
  gap: 5px;
  /* background-color: black; */
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
}

.questions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 150px;
}

.photo-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 150px;
}

.photo-list-one {
  text-align: left;
  width: 100%;
  background-color: #4F545B;
  border: none;
  border-radius: 6px;
  padding: 16px;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
  font-family: "Noto Sans";
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.photo-list-one-test {
  text-align: center;
  border: none;
  border-radius: 6px;
  padding: 16px;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
  font-family: "Noto Sans";
  font-size: 12px;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
}

.questions-list li button {
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
  font-family: "Noto Sans";
  font-size: 12px;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar-bottom-fixed {
  display: flex;
  flex-direction: column;
  width: 17%;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  gap: 5px;
  /* background-color: black; */
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-top: 1px solid #58626E
}

.sidebar-bottom-fixed-cover {
  width: 100%;
  border: 0px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  font-family: "Noto Sans";
  padding: 8px 0px;
  background-color: transparent;
  color: white;
}

.sidebar-bottom-fixed-add-photo {
  display: flex;
  width: 100%;
}

.sidebar-bottom-fixed-add-photo button {
  border: 0.5px solid rgb(194, 194, 194);
  background-color: transparent;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  font-family: "Noto Sans";
  padding: 12px 0px;
  color: white;
  margin-bottom: 150px;
  width: 100%;
}

.sidebar-bottom-fixed-cover.clicked {
  background-color: #7544b3;
}
