.main-header {
    position: fixed;
    top: 50px;
    width: 100%;
    left: 0;
    z-index: 10000;
    padding: 0 90px;
}

.main-navigation {
    height: 0;
    overflow: hidden;
    transition: 0.2s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

.main-navigation a {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-family: 'Inter';
}

.main-navigation button {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-family: 'Inter';
    display: flex;
    background-color: transparent;
    border: none;
}
  
  .main-navigation.show {
    height: 140px; 
    padding-top: 30px;
  }
  
  .main-navigation.hide {
    height: 0;
    padding-top: 0;
  }

.main-header-right {
    display: flex;
    gap: 20px;
    align-items: center;
}

.main-header-logo {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-family: 'Forum';
}

.main-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-header-full {
    /* background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(40px); */
    background-color: rgb(220, 220, 220);
    padding: 7px 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
}

@media (max-width: 900px) {
    .main-header {
        position: fixed;
        top: 50px;
        width: unset;
        left: 0;
        z-index: 10000;
        padding: 0 10px;
        width: 100%;
    }

    .main-header-full {
        padding: 10px 16px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        border-radius: 24px;
    }
}