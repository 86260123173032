.add-photo-container {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #E9E9EB;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 20px 100px 20px;
}

.photo-preview-container {
    width: 50%;
    display: flex;
    justify-content: center;
    padding-top: 100px;
}

.photo-preview {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 384px;
    height: 630px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
}


.photo-preview-media {
    width: 384px;
    height: 630px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    filter: brightness(60%);
}

.photo-preview-media.centered {
    margin-top: 200px;
    width: 190px;
    height: 190px;
    object-fit: cover;
}

.photo-preview-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 270px;
    align-items: center;
    text-align: center;
}

.photo-preview-date {
    font-size: 12px;
    line-height: 1;
    font-family: 'Playfair Display';
    z-index: 2;
    color: white;
}

.photo-preview-description {
    font-size: 12px;
    line-height: 1;
    width: 60%;
    font-style: italic;
    font-family: 'Playfair Display';
    z-index: 2;
    color: white;
}

.preview-photo-colon {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    margin-top: auto;
    margin-bottom: 50px;
    padding: 0 50px;
    z-index: 2;
    color: white;
}

.add-photo-form {
    display: flex;
    width: 50%;
    padding-top: 100px;
}

.form-group {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.form-group-photo {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.upload-icon-label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    padding: 30px;
    cursor: pointer;
}

.upload-icon {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.hidephoto {
    display: flex;
    width: 60%;
}

.hidephoto label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Inter';
  }

  .hidephoto label input {
    width: 20px;
    height: 20px;
  }

  .notify-popup {
    height: 100vh;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(40px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    padding: 0 10px
}

.notify-popup-content {
    background-color: white;
    font-family: 'Inter';
    border-radius: 16px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    max-width: 500px;
    border: 1px solid rgb(183, 183, 183)
}


@media (max-width: 700px) {
    .photo-preview { 
        height: 600px;
    }

    .photo-preview-media {
        width: 100%;
        height: 600px;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    .photo-preview-details {
        margin-top: 230px;
    }

    .add-photo-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .photo-preview-container {
        width: 100%;
        order: 1;
    }

    .add-photo-form {
        width: 100%;
        order: 2;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    .form-group {
        width: 100%;
    } 

    .form-group-photo {
        width: 100%;
    } 

    .hidephoto {
        width: 100%;
    }
}

@media (max-width: 420px) { 
    .photo-preview { 
        height: 545px;
    }

    .photo-preview-media {
        width: 100%;
        height: 545px;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    .hidephoto {
        width: 100%;
    }
}

.form-group label {
    margin-bottom: 10px;
    font-size: 16px;
    font-family: 'Noto Sans';
}

.form-group-photo label {
    margin-bottom: 10px;
    font-size: 16px;
    font-family: 'Noto Sans';
}

.form-group textarea {
    border-radius: 6px;
    width: 100%;
    font-size: 16px;
    padding: 30px;
    font-family: 'Noto Sans';
    resize: none;
    border: 1px solid #D9D9D9;
    transition: 300ms;
    line-height: 1.5;
    overflow-y: hidden;
    display: flex;
}


.form-group-photo input {
    border-radius: 6px;
    width: 100%;
    font-size: 16px;
    font-family: 'Noto Sans';
    resize: none;
    border: 1px solid #D9D9D9;
    transition: 300ms;
    line-height: 1.5;
    overflow-y: hidden;
    background-color: transparent;
}

.form-group input {
    border-radius: 6px;
    width: 100%;
    font-size: 16px;
    padding: 30px;
    font-family: 'Noto Sans';
    resize: none;
    border: 1px solid #D9D9D9;
    transition: 300ms;
    line-height: 1.5;
    overflow-y: hidden;
    background-color: transparent;
}

.form-group select {
    border-radius: 8px;
    width: 100%;
    font-size: 16px;
    padding: 10px;
    font-family: 'Noto Sans';
    resize: none;
    border: 1px solid #D9D9D9;
    transition: 300ms;
    line-height: 1.5;
    overflow-y: hidden;
    background-color: transparent;
}

.form-group textarea:focus {
    outline: none;
}

.add-photo-buttons {
    display: flex;
    gap: 10px;
}

.input-save-button-add {
    padding: 10px 40px;
    border: 0px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Noto Sans';
    display: flex;
    gap: 10px;
    margin: 0;
    background-color: #363E50;
    color: white;
}
